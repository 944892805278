import { required, email } from 'vuelidate/lib/validators';

export default {
  validations: {
    email: { required, email },
  },

  computed: {
    errorEmailEmptyField() {
      return this.$v.email.$dirty && !this.$v.email.required;
    },
    errorEmailInvalidField() {
      return this.$v.email.$dirty && !this.$v.email.email;
    },
    emailErrorText() {
      if (!this.$v.email.required) {
        return this.$t('error.required_field');
      }
      if (!this.$v.email.email) {
        return this.$t('error.invalid_email');
      }
      // if (this.serverError) {
      //   return this.$t('error.text');
      // }
    },
  },
  methods: {
    showAuthValidationErrors() {
      this.$v.$touch();
    },
  },
};
