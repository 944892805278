<template>
  <div class="auth__block">
    <div class="auth__form">
      <h1 class="title-h1 auth__title">
        {{ $t('main.forgot_password') }}
      </h1>
      <h3 class="auth__text">
        {{ $t('main.use_password_recovery') }}
      </h3>
      <div class="auth__fields-wrapper">
        <text-field
          id="email"
          v-model="email"
          placeholder="E-mail"
          class="update__field"
          :is-error="errorEmailEmptyField || errorEmailInvalidField"
          :error-text="emailErrorText"
        />
      </div>
      <div class="auth__btn-wrapper">
        <Button :is-icon="true" class="auth__btn" @click.native="resetPass"
          >{{ $t('main.update_password') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import resetPassValidation from '@/mixins/resetPassValidationMixin';

export default {
  mixins: [resetPassValidation],

  data() {
    return {
      email: '',
    };
  },

  methods: {
    resetPass() {
      if (this.$v.$invalid) {
        this.showAuthValidationErrors();
        return;
      }

      this.$store.dispatch('resetPassword', {
        email: this.email,
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
